<template>
  <div>
    <v-container pa-0 fluid v-if="breadcrumbs.length > 0">
      <v-row align="center" justify="space-between" class="font-weight-black Barra_Breadcrumbs">
        <v-col cols="12" >
          <v-breadcrumbs :items="breadcrumbs" divider="/">
            <v-breadcrumbs-item
              slot="item"
              slot-scope="{ item }"
              :to="item.to"
              :key="item.to"
              :disabled="item.disabled"
              @click.native="navegarBreadcrumbs(item)">
                {{ item.text }}
            </v-breadcrumbs-item>
          </v-breadcrumbs>
        </v-col>
      </v-row>
    </v-container>
    <v-card class="Nivel3">
      <div class="text-xs-center Paginacao_Detalhes" v-if="apuracoes.length > 1">
        <v-pagination
          v-model="page"
          class="py-2"
          :length="apuracoes.length"
          :total-visible="totalVisible"
          >
        </v-pagination>
        <v-divider></v-divider>
      </div>

      <v-row flex align="start">
        <v-col cols="12">
          <apuracao-acao-detalhes-resumo
            :acao="acao"
            :configuracao="configuracao">
            <div slot="depoisTitulo">
              <apuracao-acao-fluxo-contrato ref="fluxoApuracao"
                                  v-if="isContrato"
                                  :perguntarProximo="page < apuracoes.length"
                                  :idApuracao="objetoAcao.codApuracao"
                                  :idAcao="objetoAcao.codAcao"
                                  :status="objetoAcao.status"
                                  :acao="acao"
                                  :exibir-acompanhamento="acao.temWorkflow"
                                  :vlr-conf-apuracao="acao.vlrConfApuracao"
                                  :vlr-reservado="acao.vlrReservado"
                                  :idContrato="objetoAcao.idContrato"
                                  :pode-cancelar="acao.podeCancelar"
                                  :justificativaAlteracaoValorPagamento="justificativaAlteracaoValorPagamento"
                                  :exibir-concluir-aprovacao="podeConcluirApuracao"
                                  :exibir-solicitar-aprovacao="podeSolicitarAprovacao"
                                  :valorAtualApuracao="valorAtualApuracao"
                                  :divisao-usuario="divisaoUsuario()"
                                  @recarregar="recarregar()"
                                  @ApuracaoAcaoFluxo_irProximo="page++">
              </apuracao-acao-fluxo-contrato>
              <v-tooltip bottom v-if="acao.jaIntegrado">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" class="ma-0" icon @click="imprimir">
                    <v-icon>print</v-icon>
                  </v-btn>
                </template>
                <div>
                  {{ $t('label.exportar') }} {{ $tc('label.nota_debito', 1) }}
                </div>
              </v-tooltip>
            </div>

          </apuracao-acao-detalhes-resumo>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-expansion-panels v-model="panel" class="Painel_Detalhes_Acao" multiple accordion>
        <apuracao-acao-detalhes-foco :acao="acao" :campo-cliente="campoCliente"/>
        <apuracao-acao-detalhes-apuracao-contrato ref="detalhesApuracao"
                                         :acao="acao"
                                         :idApuracao="acao.idApuracao"
                                         :status="acao.statusApuracao"
                                         :configuracao="configuracao"
                                         :possui-coeficiente-calculo="mostrarCoeficienteCalculo"
                                         :coeficiente-calculo="coeficienteCalculo"
                                         :is-contrato="isContrato"
                                         :justificativaAlteracaoValorPagamento="justificativaAlteracaoValorPagamento"
                                         @ApuracaoDetalhes__AlteracaoJustificativa="justificativaAlteracaoValorPagamentoAlterada"
                                         @ApuracaoDetalhes__alterado="apuracaoAlterada"
                                         @ApuracaoDetalhes__pagamentoAlterado="podeConcluir"/>
        <apuracao-acao-arquivos
            :acao="acao"
            v-if="true"></apuracao-acao-arquivos>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ApuracaoAcaoFluxoContrato from './ApuracaoAcaoFluxoContrato';
import ApuracaoAcaoDetalhesResumo from '../apuracao-acao/ApuracaoAcaoDetalhesResumo';
import ApuracaoAcaoDetalhesOrigem from '../apuracao-acao/ApuracaoAcaoDetalhesOrigem';
import ApuracaoAcaoDetalhesApuracaoContrato from './ApuracaoAcaoDetalhesApuracaoContrato';
import ApuracaoContratoAcaoConcluir from './ApuracaoContratoAcaoConcluir';
import ApuracaoAcaoArquivos from './ApuracaoAcaoArquivos';
import getBasePath from '../../common/functions/api-resource';
import exportacao from '../../common/resources/downloads';
import ApuracaoAcaoDetalhesFoco from '../apuracao-acao/ApuracaoAcaoDetalhesFoco';
import ApuracaoContratoAcaoConcluirIndustria from './finalizar-lote/ApuracaoContratoAcaoConcluirIndustria';
import ApuracaoAlterarDataFim from './ApuracaoAlterarDataFim';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import { formatDatePattern } from '../../common/functions/date-utils';
import ApuracaoContratoCancelar from './ApuracaoContratoCancelar';

export default {
  name: 'ApuracaoAcaoDetalhesCard',
  components: {
    ApuracaoContratoCancelar,
    ApuracaoAlterarDataFim,
    ApuracaoContratoAcaoConcluirIndustria,
    ApuracaoAcaoDetalhesFoco,
    ApuracaoContratoAcaoConcluir,
    ApuracaoAcaoFluxoContrato,
    ApuracaoAcaoDetalhesResumo,
    ApuracaoAcaoDetalhesOrigem,
    ApuracaoAcaoDetalhesApuracaoContrato,
    ApuracaoAcaoArquivos,
  },
  data() {
    return {
      apuracaoAcaoListagemResource: this.$api.apuracaoAcaoListagem(this.$resource),
      planejamentoAcaoListagemResource: this.$api.planejamentoAcaoListagem(this.$resource),
      apuracaoAcaoResource: this.$api.apuracaoAcaoEdicao(this.$resource),
      planejamentoContratoListagemResource: this.$api.planejamentoContratoListagem(this.$resource),
      configuracaoResource: this.$api.planejamentoAcaoConfiguracao(this.$resource),
      configuracaoContratoResource: this.$api.planejamentoContratoConfiguracao(this.$resource),

      page: -1,
      panel: [0, 1],
      acao: {},
      configuracao: {},
      configuracaoContrato: {},
      apuracoes: [],
      objetoAcao: null,
      idContrato: null,
      justificativaAlteracaoValorPagamento: null,
      coeficienteCalculo: null,
      podeConcluirApuracao: true,
      podeSolicitarAprovacao: false,
      breadcrumbs: [],
      valorAtualApuracao: null,
    };
  },
  watch: {
    page: {
      handler() {
        this.objetoAcao = this.apuracoes[this.page - 1];
        this.objetoAcao.idContrato = this.idContrato;
        this.setFiltroApuracaoContratoNivel3(this.objetoAcao);
        this.carregarApuracao(this.objetoAcao.codApuracao);
      },
    },
  },
  computed: {
    totalVisible() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return 5;
      }
      return 12;
    },
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    ...generateComputed('APU_CONTR', [
      'canAccessCRUD',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    isUsuarioFuncionario() {
      return this.usuarioLogado.tipo === 'FUNCIONARIO';
    },
    isContrato() {
      return !!this.idContrato;
    },
    campoCliente() {
      if (!this.configuracaoContrato.passo2) {
        return '';
      }
      const { listaCliente } = this.configuracaoContrato.passo2;
      const { campoCliente } = (listaCliente || []).find((el) => el.label === 'campo_cliente') || {};
      return (campoCliente || {}).label || '';
    },
    mostrarCoeficienteCalculo() {
      return this.possuiCoeficienteCalculoHabilitado
          && this.visualizaCoeficienteCalculoApuracao;
    },
    possuiCoeficienteCalculoHabilitado() {
      return this.configuracaoContrato
          && this.configuracaoContrato.passo3
          && this.configuracaoContrato.passo3.coeficienteCalculo;
    },
    visualizaCoeficienteCalculoApuracao() {
      if (this.configuracaoContrato.passo2
          && this.configuracaoContrato.passo2.listaPadrao) {
        const listaCampo = this.configuracaoContrato.passo2.listaPadrao
          .filter((c) => c.label === 'coeficiente_calculo');
        if (listaCampo.length > 0) {
          const { fluxos } = listaCampo[0];
          return fluxos && fluxos.indexOf('APURACAO') > -1;
        }
        return false;
      }
      return false;
    },
    divisao() {
      return this.usuarioLogado.divisao ? this.usuarioLogado.divisao.split(',') : [];
    },
  },
  methods: {
    ...mapActions('filtros', [
      'setFiltroApuracaoContratoNivel3',
    ]),
    trocaRota() {
      const { name, params: previousParams, query } = this.$route;
      const { id: idApuracaoUrl } = previousParams;
      const { codApuracao: idApuracaoObjeto, codAcao: idAcao } = this.objetoAcao;

      if (idApuracaoUrl === idApuracaoObjeto) return;

      const params = { ...previousParams };
      params.idAcao = idAcao;
      params.id = idApuracaoObjeto;
      params.idContrato = this.acao.idContrato;
      this.$router.push({ name, params, query });
    },
    podeCancelar(item) {
      return (item.statusApuracao === 'AGUARDANDO_APURACAO'
        || item.statusApuracao === 'APURACAO_PREVIA')
        && this.canAccessCRUD
        && this.isUsuarioFuncionario;
    },
    apuracaoAlterada(vlrConfApuracao = 0) {
      this.valorAtualApuracao = vlrConfApuracao;
    },
    podeConcluir(paamentoAlterado) {
      this.podeConcluirApuracao = paamentoAlterado;
      this.podeSolicitarAprovacao = !paamentoAlterado;
    },
    alterarDataFimApuracao(acao, data) {
      acao.dtaFimApuracao = formatDatePattern(data, 'YYYY-MM-DD');
    },
    justificativaAlteracaoValorPagamentoAlterada(novoTexto) {
      this.justificativaAlteracaoValorPagamento = novoTexto;
    },
    carregarAcao(idAcao) {
      return this.planejamentoAcaoListagemResource.buscarAcao({ idAcao })
        .then((res) => {
          this.acao = {
            ...this.acao,
            ...res.data.passoGeral.mapaExtensoes,
            ...res.data.passoGeral.mapaCamposDinamicos,
            ...res.data.passoComportamento.mapaExcecoes,
          };
          this.acao.meta = res.data.passoComportamento.meta;
        })
        .then(this.carregarContrato)
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    validarForm() {
      this.$refs.detalhesApuracao.validarForm();
    },
    imprimir() {
      const { idApuracao } = this.acao;
      const basePath = getBasePath('recebimento', 'relatorio');
      const resource = exportacao(this.$http);

      resource.downloadGet(basePath, {
        param: `?nomeArquivo=${this.acao.origemContrato}`,
        url: `apuracao/${idApuracao}/nota-debito/`,
      }).catch(() => {
        this.$toast('Erro ao exportar. Tente novamente.');
      });
    },
    carregarContrato() {
      if (!this.acao.idContrato) return;
      const { idContrato } = this.acao;
      const justGeneralStep = true;
      this.planejamentoContratoListagemResource.buscarContrato({ idContrato, justGeneralStep })
        .then((res) => {
          this.acao = {
            ...this.acao,
            ...res.data.passoGeral.mapaExtensoes,
            ...res.data.passoGeral.mapaCamposDinamicos,
            descricaoContrato: res.data.passoGeral.descricao,
            dtaVigenciaFim: res.data.passoGeral.dtaVigenciaFim,
            dtaVigenciaInicio: res.data.passoGeral.dtaVigenciaInicio,
          };
          this.coeficienteCalculo = res.data.passoGeral.valorBase;
        }).then(() => {
          this.$refs.detalhesApuracao.formatarObjeto();
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    carregarApuracao(idApuracao) {
      this.buscarJustificativaAlteracaoValor(idApuracao);
      this.apuracaoAcaoListagemResource.buscarApuracao({ idApuracao })
        .then((res) => {
          this.acao = { ...res.data };
          this.acao.recebimento = this.acao.valorRecebimento;
          this.valorAtualApuracao = this.acao.vlrConfApuracao;
          this.trocaRota();
        })
        .then(() => {
          Promise.all([
            this.carregarConfiguracao(this.acao.idAcao),
            this.carregarAcao(this.acao.idAcao),
            this.carregarConfiguracaoContrato(this.acao.idContrato),
          ]).then(() => {
            this.$refs.detalhesApuracao.formatarObjeto();
            if (!this.isContrato) {
              setTimeout(this.$refs.fluxoApuracao.carregaInformacoesFluxo);
            }
          });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    carregarConfiguracao(idAcao) {
      return this.configuracaoResource.buscarConfigSnapshot({ idAcao }).then((res) => {
        this.configuracao = res.data;
      })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    carregarConfiguracaoContrato(idContrato) {
      if (!idContrato) return () => {};
      return this.configuracaoContratoResource.buscarConfigSnapshot({ idContrato })
        .then((res) => {
          this.configuracaoContrato = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarJustificativaAlteracaoValor(idApuracao) {
      this.apuracaoAcaoResource.buscarJustificativaAlteracaoValor({ idApuracao })
        .then((res) => {
          this.justificativaAlteracaoValorPagamento = res.data.justificativa;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    divisaoUsuario() {
      if (this.acao && this.acao.idDivisao) {
        return !!this.divisao.includes(this.acao.idDivisao.toString());
      }
      return false;
    },
    recarregar() {
      if (this.objetoAcao.codApuracao) {
        this.carregarApuracao(this.objetoAcao.codApuracao);
      }
    },
    navegarBreadcrumbs(item) {
      if (item.disabled) {
        return;
      }
      if (item.nivel === 2) {
        this.breadcrumbs.splice(2, 1);
      } else {
        this.breadcrumbs.splice(1, 2);
      }

      this.$router.push({
        name: item.to,
        params: {
          breadcrumbsParam: this.breadcrumbs,
        },
      });
    },
    montaBreadcrumbsNotaDebito() {
      this.breadcrumbs = [{
        text: this.$tc('title.nota_debito', 2),
        to: 'notas-debito-consulta',
        disabled: false,
      }, {
        text: this.$tc('label.detalhe', 1),
        disabled: true,
      }];
    },
  },
  beforeMount() {
    this.idContrato = this.$route.params.idContrato;

    this.objetoAcao = {
      codApuracao: this.$route.params.id,
      codAcao: this.$route.params.idAcao,
      idContrato: this.idContrato,
      status: this.$route.status,
    };

    this.acao = {
      idApuracao: this.$route.params.id,
      idAcao: this.$route.params.idAcao,
      idContrato: this.idContrato,
      statusApuracao: this.$route.params.status,
      idAcaoPrevia: this.$route.params.idAcaoPrevia,
    };
    this.apuracoes = this.$route.params.apuracoes
      ? this.$route.params.apuracoes
      : [this.objetoAcao];

    this.origem = this.$route.params.origem;

    if (this.$route.params.breadcrumbsParam) {
      this.breadcrumbs = this.$route.params.breadcrumbsParam;
    } else if (this.origem && this.origem === 'NOTA_DEBITO') {
      this.montaBreadcrumbsNotaDebito();
    }
  },
  mounted() {
    if (this.objetoAcao.codApuracao) {
      this.setFiltroApuracaoContratoNivel3(this.objetoAcao);
      if (this.apuracoes && this.apuracoes.length > 0) {
        const apuracao = this.apuracoes
          .filter((aa) => aa.codApuracao === this.objetoAcao.codApuracao)[0];
        if (apuracao) {
          this.page = this.apuracoes.indexOf(apuracao) + 1;
        }
      } else {
        this.carregarApuracao(this.objetoAcao.codApuracao);
      }
    }
  },
};
</script>

<style lang="scss">

  .Barra_Breadcrumbs {
    .v-breadcrumbs {
      padding: 0.5em 0 1em 0;
    }
    ul:not(.md-list)>li+li {
      margin-top: 0 !important;
    }
  }
  .Paginacao_Detalhes {
    ul:not(.md-list)>li+li {
      margin-top: 0 !important;
    }
  }
  .Nivel3 .card-header {
    background-color: #fff;
    color: #000;
  }
  .Painel_Detalhes_Acao .v-expansion-panel__header__icon{
    margin-right: 1em;
  }
  .Painel_Detalhes_Acao .v-expansion-panel__header{
    padding:0;
    margin-left: 1.5em;
    min-height: 42px;
  }
</style>
